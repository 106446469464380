import Loader from "./Loader";
import { Flex } from "@aws-amplify/ui-react";

export default ({
  label,
  isLoading,
}: {
  label?: string;
  isLoading?: boolean;
}) =>
  isLoading === undefined || isLoading === true ? (
    <Flex justifyContent="center" grow={1} marginTop={12} textAlign="center">
      <Loader />

      {label && <Flex>{label}</Flex>}
    </Flex>
  ) : (
    <></>
  );
